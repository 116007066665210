import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { getPlans } from 'checkout-module/checkout/api/billingApi'
import PlanBlock from 'checkout-module/checkout/ui/transmission-plans-page/PlanBlock'
import { canTheSubscriptionBeUpgraded } from 'checkout-module/checkout/ui/shared/canTheSubscriptionBeUpgraded'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import { siteId } from 'ecommerce-module/config/default'
import Spinner from 'shared-module/components/Spinner'
import { useECommerceProductsActions } from 'ecommerce-module/core/eCommerce.hook'
import { useLDFlags } from 'spypoint/src/launchDarkly-module/launchDarklyFlagsContext'
import { BasketProvider } from 'spypoint/src/ecommerce-module/BasketContext'
import { useECommerceFlag } from 'spypoint/src/ecommerce-module/hooks/UseEcommerceFlag'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  blocks: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: '2',
    maxWidth: '1000px',
    justifyContent: 'center',
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
}))

const monthlyPeriodType = 'month_by_month'
const annualPeriodType = 'annual'

const PlanList = ({ subscription, planPeriod = 'month_by_month' }) => {
  const classes = useStyles()
  const history = useHistory()
  const camera = useSelectedCamera()

  const [plans, setPlans] = useState(null)
  const eCommerceEnabled = useECommerceFlag()
  const { getProducts } = useECommerceProductsActions()

  useEffect(() => {
    if (!eCommerceEnabled && !plans) {
      getPlans().then(plans => setPlans(plans))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchProducts = async () => {
      if (eCommerceEnabled) {
        const plans = await getProducts()
        setPlans(plans.products)
      }
    }
    fetchProducts()
  }, [eCommerceEnabled])

  const buildRedirectParams = plan => {
    const params = new URLSearchParams(window.location.search)
    params.append('p', plan.plan.id)
    params.append('f', plan.frequency)

    return params.toString()
  }

  const redirectToPaymentPage = plan => history.push('/camera/' + camera.id + '/subscribe?' + buildRedirectParams(plan))

  const renderBlocks = () => {
    const currentPlan = subscription.plan

    const setPlans = () => subscription?.plan.id === 'Free'
      ? [subscription.plan, ...plans?.plans?.filter(plan => plan.period === planPeriod)]
      : plans?.plans?.filter(plan => plan.period === planPeriod)

    const setLegacyPlans = () => {
      if (currentPlan.isActive) {
        return subscription?.plan.id === 'Free'
          ? plans.filter(plan => plan.isActive)
          : plans.filter((plan) => (plan.id === 'Basic' || plan.id === 'Standard' || plan.id === 'Premium'))
      } else return plans.filter(plan => plan.isActive && plan.isFree)
    }

    const activePlans = eCommerceEnabled ? setPlans() : setLegacyPlans()

    return (
      <div className={classes.blocks}>
        { activePlans.map(plan => {
          plan.canUpgradeMonthByMonth = canTheSubscriptionBeUpgraded(subscription, plan, monthlyPeriodType, eCommerceEnabled)
          plan.canUpgradeAnnual = canTheSubscriptionBeUpgraded(subscription, plan, annualPeriodType, eCommerceEnabled)

          return <BasketProvider><PlanBlock key={plan.id} plan={plan} subscription={subscription} periodType={planPeriod} onSelectPlan={redirectToPaymentPage} /></BasketProvider>
        }) }
      </div>
    )
  }

  return (
    <Box className={classes.root}>
      { subscription && plans ? renderBlocks() : <Box mt={2} mb={4}><Spinner /></Box> }
    </Box>
  )
}

export default PlanList
