import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import AppBarContainer from 'shared-module/components/app-bar/AppBarContainer'
import useUser from 'user-module/user/core/useUser'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'
import { useCart } from '../../core/cart.hooks'
import CartTotalLine from '../cart-lines/CartTotalLine'
import CartSavingsLine from '../checkout-page/checkout-lines/CartSavingsLine'
import CartContent from './CartContent'
import CartSection from './CartSection'
import DirectPurchaseWrapper from './DirectPurchaseWrapper'
import EmptyCart from './EmptyCart'
import CartButtons from './CartButtons'
import CartBanner from '../cart-items/CartBanner'
import { Grid } from '@material-ui/core'
import CartDivider from 'spypoint/src/checkout-module/order/ui/cart-lines/CartDivider'
import LineTitle from 'spypoint/src/checkout-module/order/ui/cart-lines/LineTitle'
import Amount from 'shared-module/components/amount/Amount'
import RemoveCartItemButton from 'spypoint/src/checkout-module/order/ui/cart-page/RemoveCartItemButton'
import { useLDFlags } from 'spypoint/src/launchDarkly-module/launchDarklyFlagsContext'
import eCommerceApi from 'ecommerce-module/api/eCommerceApi'
import { useECommerceFlag } from '../../../../ecommerce-module/hooks/UseEcommerceFlag'

const CartPage = () => {
  const { t } = useTranslation()
  const user = useUser()
  const userClubMembership = useUserClubMembership()
  const eCommerceEnabled = useECommerceFlag()

  const {
    cartItems,
    cartItemsCount,
    isUnavailableItemInCart,
  } = useCart()

  const [basket, setBasket] = useState(null)
  const [productItems, setProductItems] = useState([])

  const fetchData = async () => {
    try {
      const basketId = localStorage.getItem('basketId')
      if (basketId && basketId !== 'undefined') {
        const basketDetails = await eCommerceApi.getBasket(basketId)
        if (basketDetails && basketDetails.basketId) {
          setBasket(basketId)
          setProductItems(basketDetails.productItems)
        } else {
          const newBasket = await eCommerceApi.createBasket()
          if (newBasket?.basketId) {
            setBasket(newBasket.basketId)
            localStorage.setItem('basketId', newBasket.basketId)
            setProductItems(newBasket.productItems)
          }
        }
      } else {
        const newBasket = await eCommerceApi.createBasket()
        if (newBasket?.basketId) {
          setBasket(newBasket.basketId)
          localStorage.setItem('basketId', newBasket.basketId)
          setProductItems(newBasket.productItems)
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (eCommerceEnabled) {
      fetchData()
    }
  }, [eCommerceEnabled])

  const handleDeleteItem = async (basketId, itemId) => {
    try {
      await eCommerceApi.deleteItemFromBasket(basketId, itemId)
      const updatedItems = productItems.filter(item => item.itemId !== itemId)
      setProductItems(updatedItems)
    } catch (err) {
      console.error(err)
    }
  }

  if (!user.cartEnabled) {
    return <Redirect to="/" />
  }

  return (
    <DirectPurchaseWrapper clubMembership={userClubMembership}>
      <AppBarContainer Icon={ShoppingCartIcon} title={t('menu.cart')} />
      <CartBanner isInsidersClubMember={userClubMembership.isMember} />
      { eCommerceEnabled
        ? (
            !productItems || productItems.length === 0
              ? (
                <EmptyCart />
                )
              : (
                <>
                  { basket && (
                    <CartSection>
                      { productItems?.map((product, index) => (
                        <Grid key={product.productId || index}>
                          <Grid container justifyContent="space-between" spacing={3}>
                            <LineTitle full bold>
                              { t('app:checkout_process.photo_transmission_plan.title', { planType: product.productName }) }
                            </LineTitle>
                          </Grid>
                          <Grid container justifyContent="space-between" spacing={3}>
                            <LineTitle bold>{ t('app:checkout_process.price') }</LineTitle>
                            <LineTitle bold align="right">
                              <Amount value={product.price} currencyCode={user.currencyCode} />
                            </LineTitle>
                          </Grid>
                          <RemoveCartItemButton
                            productId={product.itemId}
                            onDelete={() => {
                              handleDeleteItem(basket, product.itemId)
                            }}
                          />
                          <CartDivider />
                          { product.price >= 0 && (
                            <Grid container justifyContent="space-between" spacing={3}>
                              <LineTitle bold>{ t('app:checkout_process.cart.total_before_tax').toLocaleUpperCase() }</LineTitle>
                              <LineTitle align="right" bold>
                                <Amount value={product.productTotal} currencyCode={user.currencyCode} />
                              </LineTitle>
                            </Grid>
                          ) }
                        </Grid>
                      )) }
                      <CartSavingsLine />
                    </CartSection>
                  ) }
                  <CartButtons isUnavailableItemInCart={null} />
                </>
                )
          )
        : (
            cartItemsCount === 0
              ? (
                <EmptyCart />
                )
              : (
                <>
                  <CartSection>
                    <CartContent products={cartItems.products} />
                    <CartTotalLine />
                    <CartSavingsLine />
                  </CartSection>
                  <CartButtons isUnavailableItemInCart={isUnavailableItemInCart} />
                </>
                )
          ) }
    </DirectPurchaseWrapper>
  )
}

export default CartPage
